//
// calendarで、選択した日、の処理。(予約、削除)
//
// フォーム表示に必要な item をもらう、emitするための関数をもらう。calendarから。
// サーバーからの応答(serverからsocket emitされたもの)の表示はこの上のcalenderでReactの表示サイクルにのっとって行う。
//
import React from 'react'
import './dayform.scss'
import {Flags} from './App'
//import {SubmitHandler} from 'react-hook-form' // SubmitHandler<any>とかはやはりだめだった。
import {format} from 'date-fns'
import {UserType} from './caltypes'
import {Line, kind2tag} from './utilline'
import {ReservationRow, ItemDType} from './caltypes'
import {is_admin} from './utilrole'
import {HoyoAt} from './parts/HoyoAt'
import {PhoneOrMail} from './parts/PhoneOrMail'
import {CheckNote} from './parts/CheckNote'
import {EditNote} from './parts/EditNote'
//import {EditNote2} from './parts/EditNote2'
import {TitleCancel, CheckboxHeader, StatusIcon, LineInfoBtn} from './dayformSub'
import {SogiOption} from './parts/SogiOption'
import {SogiOption2} from './parts/SogiOption2'
import {SogiOption3} from './parts/SogiOption3'
import {AntiOption} from './parts/AntiOption'



//
// 〇misc funcs util
//
// メモ欄など属性を入力、検査などをするために...
// const is_funeral = (line: Line) => line.row.kind.includes('葬儀') ? true: false
// @2022-0820 葬儀単独はなし、通夜が翌日の葬儀をペア予約として連携するのみ。
//

const is_mine = (line: Line, user: UserType) => line.row.user === user.username
// idがあるもの、tuya, hoyoN のみ sogi は連携だし、 anti は編集不要でいいかな。
const is_editable = (line: Line) => (0 <= line.row.id && 
  (
    //2024 １日葬儀が新設されたけどここは追加(tuya-l, tuya-a)でいいはず。
    kind2tag(line.row.kind) === 'tuya' || kind2tag(line.row.kind).startsWith('hoyo') ||
    kind2tag(line.row.kind) === 'tuya-h' || kind2tag(line.row.kind) === 'tuya-l' ||
    kind2tag(line.row.kind) === 'tuya-a'
  )
)
//const is_newrow = (line: Line) => line.row.id < 0 // まだdbにはない row (reservation)
//
// 〇is_show_node  予約できない項目は属性(note)を表示しない
//
const is_show_note = (line: Line) => {
  if(! line.enable){
    return false
  }
  else{
    return true
  }
}

//
// 〇sliced_  予約内容の表示テキストをつくる, 内容と状態を含めて説明の文章を短めに。
//
// 予約の内容説明テキスト部分には ellipsisが かかっている
// 詳細は adminにゆるされた alert windowで表示することにしたのでここではユーザー向け文章とする。
//
function sliced_(login_user: string, line: Line){

  const tag= kind2tag(line.row.kind)
  let disp_kind= ''
  switch(tag){
    case 'tuya': disp_kind='葬儀(通夜安置と翌日の葬儀)'; break
    case 'sogi': disp_kind= '(葬儀)'; break //この部分はフォームに表示されない、単独1日予約できない仕様なので。
    case 'anti': disp_kind= '安置(安置延長)'; break
    //case 'tuya': disp_kind= '金蓮寺会館通夜(翌日の葬儀含む)'; break
    case 'hoyo9': disp_kind= '法要(９時)'; break
    case 'hoyo10': disp_kind= '法要(10時)'; break
    case 'hoyo11': disp_kind= '法要(11時)'; break
    case 'hoyo12': disp_kind= '法要(12時)'; break
    case 'hoyo13': disp_kind= '法要(13時)'; break
    case 'hoyo14': disp_kind= '法要(14時)'; break
    case 'hoyo15': disp_kind= '法要(15時)'; break
    case 'hoyo16': disp_kind= '法要(16時)'; break
    case 'tuya-h': disp_kind='法要室葬儀(通夜安置と翌日の葬儀)'; break
    case 'sogi-h': disp_kind='(法要室葬儀)'; break
    case 'anti-h': disp_kind='法要室安置(安置延長)'; break
    //2024 new
    case 'tuya-l': disp_kind= '葬儀(金蓮寺ホール)'; break
    case 'sogi-l': disp_kind= '葬儀(金蓮寺ホール)'; break
    case 'anti-l': disp_kind= '葬儀(金蓮寺ホール)'; break
    case 'tuya-a': disp_kind= '安置(ミニホール)'; break
    case 'sogi-a': disp_kind= '安置(ミニホール)'; break
    case 'anti-a': disp_kind= '安置(ミニホール)'; break

  }
  //const login= user.username // 現在のログインユーザ
  let text= ''
  const owner= line.row.user === login_user ? line.row.user : '他の方'
  
  // できる つまり空で制約もない
  if( line.enable){
      text= `${disp_kind} 予約可能`
  }
  // 空なのにできない
  else if( line.empty ){
    text = `${disp_kind} 予約不可`
  }
  // できない、空でない
  else {
    text= `${disp_kind} 予約済 ${owner} `
  }
  return text
}
//
// 〇check notes
//
// hoyoなら kind, at, owner, addr, phoneが入力されているかを検査
// funeralなら owner, startを検査
// (2024 check内容は異なるので L A 向けは check_notes2を新設)
// (ただし is_xxx の判定は古いほうも手をいれないとだめ。)
function check_notes(row: ReservationRow):{success: boolean; msg: string}{
  
  // @2022-0820 通夜 tuya タグが葬儀のペア予約の初日なので。
  //const is_funeral_row= (row: ReservationRow) => row.kind.includes('通夜') ? true: false
  const is_funeral_row= (row: ReservationRow) => {
    const kind= row.kind
    if(kind === '通夜' || kind === '通夜H' ) return true
    else return false
    }
  const is_hoyo_row= (row: ReservationRow) => row.kind.includes('法要') ? true: false
  const is_none= (prop: string) => prop === ''
  
  const tes_phone= (text: string) => /^[0-9-]+$/.test(text)
  const tes_mail= (text: string) => /@/.test(text)
  

  // ただし、属性によっては空でよい場合もあるので...
  //@2022-1003 葬儀については（急ぎの予約もあるので）家の名前のみ必須とする。
  // (-->通夜の時間、葬儀開始、終了、宗派は予約時必須としない。)
  //
  // 葬儀の場合のチェック
  //
  if(is_funeral_row(row)){

    // 家の名前だけは必須
    if(is_none(row.funeral_owner)) return {success: false, msg: '家の名前を記入してください'}

  }
  //
  // 法要の場合のチェック
  // nenki(四十九日、一周忌、三回忌など) misc(墓じまい、仏壇開眼など) はどちらかがあれば OKとする。
  // nenki/misc は両方チェックできる。のでチェックされると空はやはりNGとしないとややこしい。
  // 
  if(is_hoyo_row(row)){
    
    //
    // 年季法要、かその他墓じまいなど、どちらも空ならエラー。
    //
    if(is_none(row.hoyo_nenki) && is_none(row.hoyo_misc)){
      return {success: false, msg: '年忌内容またはその他の内容を記入してください'}
    }

    //
    // at 自宅 で addr 空から、なら addrを(自宅)として OK addrは住所変更があれば何か入力されている。
    // at 金蓮寺 で addr 空ならエラー、金蓮寺で法要のみ, 金蓮寺 寺内にて会食希望 のいずれか。
    // at その他　で 空ならエラー、 住所などの説明が必要。
    // -->自宅選択時に(自宅)をいれるべきか。そうするとここでは addrのからチェックだけですむが。...

    // 法要場所の指定は必須(自宅、金蓮寺、その他)
    //
    if(is_none(row.hoyo_at)) return {success: false, msg: '法要場所が選択されていません'}
    //
    // 1. at自宅で addr 空はOK (変更があれば記入してもらうので)
    // 2. at金蓮寺で addr空ならエラー ラジオ選択ミス
    // 3. atその他で addr空ならエラー 入力必須
    if(row.hoyo_addr === '') {
      if(row.hoyo_at === '自宅' ) {} // OK
      if(row.hoyo_at === '金蓮寺'){return {success: false, msg: '会食の有無を選択してください'} }
      if(row.hoyo_at === 'その他'){return {success: false, msg: '法要場所を入力してください'}}
    }
    
    //
    // hoyo_addr 単独入力欄は廃止(@2022-1027)
    //if(is_none(row.hoyo_addr)) return {success: false, msg: 'お参りする場所を入力してください'}
    //
    
    //
    // 施主名、施主電話は必須なのでこれでよい。
    //
    if(is_none(row.hoyo_owner)) return {success: false, msg: '施主名を入力してください'}
    if(is_none(row.hoyo_phone)) return {success: false, msg: '施主電話番号を入力してください'}

      //連絡先のチェックは？可能か。
    if(row.memo==='') return{ success: false, msg:'予約確認のための連絡先がありません'}
    if(tes_phone(row.memo)===false && tes_mail(row.memo)===false ){
      return {success: false, msg:'予約確認のための連絡先を正しく入力してください'}
    }
  }
  //
  // エラーなし、なので 最後に、OKで　出る。
  //
  return {success: true, msg: 'OK'}
}// end check notes

//
// 2024 new
// check_notes2 for L, A
// 以前はすべて↑の check_notes() で書いておいて hoyo* では check しない、などの緩和をしていた。
// 2024では L, Aの内容はこれまでのと独立させて チェック詳細を反映しやすいように分けた。 insr をみよ。
//
function check_notesL(row: ReservationRow):{success: boolean; msg: string}{

  // 家の名前だけは必須
  if(row.funeral_owner === ''){
    return {success: false, msg: '家の名前を記入してください'}
  }


  //
  // エラーなし、なので 最後に、OKで　出る。
  //
  return {success: true, msg: 'OK'}
}

function check_notesA(row: ReservationRow):{success: boolean; msg: string}{

  // 家の名前だけは必須
  if(row.funeral_owner === ''){
    return {success: false, msg: '家の名前を記入してください'}
  }


  //
  // エラーなし、なので 最後に、OKで　出る。
  //
  return {success: true, msg: 'OK'}
}



  //
  //  通常の editmode では抑止している 部分を global switchの Admin edit mode では可能とする。
  //  ここから呼ばれる subの HoyoAtなどにも伝搬
  //
  type ALineProps = {
    line: Line;
    login: UserType;
    users: UserType[];
    insr: (line: Line) => void;
    delr: (line: Line) => void;
    updr: (line: Line) => void;
    adminEdit: boolean; //@2022-1105
  }
  const ALine = ({line, login, users, insr, delr, updr, adminEdit}: ALineProps) => {
    const [editmode, setEditmode] = React.useState<boolean>(false)

    //debug
    //console.log('Aline', line.row)//新しい 安置A とかもここに入ってきている。

    const handle_editmode = () => {
      setEditmode( prev => !prev )
    }

    //
    // 金蓮寺会館以外に法要室葬儀を追加したので facility を 「会館」「法要室」 のどちらか、と定義しなおした。
    //  (また、空 '' もありだ。そして、hoyo_at では 金蓮寺をUIでチェックされたら、facilityは 「法要室」 をいれている。)
    // だからこの facility 定義は変えられない(dbにはいる)のでここではUI表示の自由度として定義。
    const disp_facility = (row_facility: string) => {
      switch(row_facility){
        case '会館': return '金蓮寺会館'
        case '法要室': return '法要室'
        case 'ホール': return '金蓮寺ホール'
        case '安置室': return '安置室ミニホール'
        default: return row_facility //空など それ以外はそのまま
      }
    }

    //
    // sogi, anti は単独では（予約拡張ができたので）使わない。
    // (Alone表示して詳細をみるための管理者で使ってもよいが....とりあえず省略する。@2022-1027) 
    // 2024 new L, A 追加
    //
    const tag= kind2tag(line.row.kind)
    if(tag === 'sogi' || tag === 'sogi-h' || tag === 'sogi-l' || tag === 'sogi-a'){
      return <></>
    }

    if(tag === 'anti' || tag === 'anti-h' || tag === 'anti-l' || tag === 'anti-a' ){
      return <></>
    }

    //
    // 実行ボタンの説明 (上から順に優先)
    //
    const prompt = (line: Line, login: UserType) => {
      if(line.enable) return '予約を押してください'
      if(editmode) return '更新可能です'
      if(line.erasable) return '削除可能です'
      if(is_mine(line, login)) return '詳細を確認できます'
      if(is_admin(login.username)) return '管理者は詳細を確認できます'
      return '...'
    }

    //
    // 編集モードだけど変更させると矛盾する場合に readonly としている。
    // つまり 最初の予約作成時は editmode ではないので readonly ではない。
    // ただし editmode でも globalに fAdminEdit なら　やはり矛盾するかもしれない変更も可能とする。
    //
    //const can_change= () => (! editmode) || adminEdit
    const forced_readonly = () => editmode && !adminEdit // 

    return(<>
      {/* <div>dbg:{line.row.kind}, {line.row.facility}</div> */}

      <div className="input-group" >
        {/* 状態アイコン */}
        <div style={{margin: "6px 8px 4px 6px"}}>
          <StatusIcon login={login.username} line={line} />
        </div>
        {/* 内容説明テキスト */}
        <div className="form-control ellipsis" style={{backgroundColor: "WhiteSmoke"}}>
          {sliced_(login.username, line)}
          {editmode ? '*' : ' '}
        </div>
      </div>

      {/* まず従来の 通夜 通夜H のパタンはそのまま この２つのkindは 共通処理ができている。 */}
      {/* ２段目 葬儀のメモ 家、開始時刻 (現在会館?設備？は金蓮寺会館のみ*/}
      {/* 条件変更  (is_funeral(line) && is_show_note(line) ) から editmodeも考慮して */}
      { ((line.row.kind === '通夜' || line.row.kind === '通夜H') && (is_show_note(line) || editmode ) ) && <>
      <div className="input-group">
        <label className="input-group-text">使用設備</label> 
        <input type="text" className="form-control" style={{backgroundColor: "WhiteSmoke"}}
          readOnly={true} value={disp_facility(line.row.facility)} />
      </div>
        
      {/* editmodeではこのオプションは変えさせない ただし、adminEditなら OK */}
      {/* readonlyは編集させない、の意味なので editmode でかつ adminEditでないときだけ readonlyに。 */}
      <SogiOption line={line} readonly={forced_readonly()}></SogiOption>

      <EditNote prev={line.row.funeral_tuya}
        label="通夜開始" placeholder="通夜開始時間を入力してください"
        cb={(t:string)=>{line.row.funeral_tuya= t}} readonly={false}
      />

      <EditNote prev={line.row.funeral_owner}
        label="家の名前" placeholder="家の名前を入力してください"
        cb={(t:string)=>{line.row.funeral_owner= t}} readonly={false}
      />

      <EditNote prev={line.row.funeral_start}
        label="開始時間" placeholder="葬儀開始時間を入力してください"
          cb={(t:string)=>{line.row.funeral_start = t}} readonly={false}
      />

      <EditNote prev={line.row.funeral_end}
        label="終了時間" placeholder="葬儀終了時間を入力してください"
        cb={(t:string)=>{line.row.funeral_end= t}} readonly={false}
      />

      <EditNote prev={line.row.funeral_denomination}
        label="宗派" placeholder="宗派を入力してください"
        cb={(t:string)=>{line.row.funeral_denomination= t}} readonly={false}
      />
      </>}

      {/* tuya-l tag */}
      {/* L ホール (金蓮寺ホール2024) */}
      { (line.row.kind === '通夜L'  &&  (is_show_note(line) || editmode ) ) && <>
      <div className="input-group">
        <label className="input-group-text">使用設備</label> 
        <input type="text" className="form-control" style={{backgroundColor: "WhiteSmoke"}}
          readOnly={true} value={disp_facility(line.row.facility)} />
      </div>
        
      {/* editmodeではこのオプションは変えさせない ただし、adminEditなら OK */}
      {/* readonlyは編集させない、の意味なので editmode でかつ adminEditでないときだけ readonlyに。 */}
      <SogiOption2 line={line} readonly={forced_readonly()}></SogiOption2>

      <EditNote prev={line.row.funeral_owner}
        label="家の名前" placeholder="家の名前を入力してください"
        cb={(t:string)=>{line.row.funeral_owner= t}} readonly={false}
      />

      {/* 2024new 通夜時間 funeral_wake (funeral_startと同じかもしれないけど...) */}
      <EditNote prev={line.row.funeral_wake}
        label="通夜時間" placeholder="通夜開始時間を入力してください"
        cb={(t:string)=>{line.row.funeral_wake= t}} readonly={false}
      />

      <EditNote prev={line.row.funeral_start}
        label="葬儀/法事時間" placeholder="葬儀/法事の開始時間を入力してください"
          cb={(t:string)=>{line.row.funeral_start = t}} readonly={false}
      />

       {/* 2024new 出棺時間 coffin_start */}
      <EditNote prev={line.row.coffin_start}
        label="出棺時間" placeholder="出棺時間を入力してください"
          cb={(t:string)=>{line.row.coffin_start = t}} readonly={false}
      />

      <EditNote prev={line.row.funeral_end}
        label="終了時間" placeholder="葬儀終了時間を入力してください"
        cb={(t:string)=>{line.row.funeral_end= t}} readonly={false}
      />

      {/* 2024 new これも denominationかもしれないが一応分けた。-->denominationに. */}
      <EditNote prev={line.row.funeral_denomination}
        label="宗派/導師(寺院)" placeholder="宗派/導師(寺院)などを入力してください"
        cb={(t:string)=>{line.row.funeral_denomination= t}} readonly={false}
      />
      </>}

     {/* 2024 通夜A ミニホール安置 の表示/編集 */}
     { ( line.row.kind === '通夜A' && (is_show_note(line) || editmode ) ) && <>
      <div className="input-group">
        <label className="input-group-text">使用設備</label> 
        <input type="text" className="form-control" style={{backgroundColor: "WhiteSmoke"}}
          readOnly={true} value={disp_facility(line.row.facility)} />
      </div>
        
        {/* 1,2,3,4日 2024 1日安置のパタンはnew*/}
      <SogiOption3 line={line} readonly={forced_readonly()}></SogiOption3>

      <EditNote prev={line.row.funeral_owner}
        label="家の名前" placeholder="家の名前を入力してください"
        cb={(t:string)=>{line.row.funeral_owner= t}} readonly={false}
      />

      {/* 2024 new 葬儀の予定 これは メモとして考えておく 宗派などをメモにすべきか!?*/}
      {/* いずれにせよ funeral_memo は新設フィールド */}
      {/* ４択なのでOpitionだ。 */}
      {/* <EditNote prev={line.row.funeral_memo}
        label="葬儀の予定" placeholder="出棺時間を入力してください"
          cb={(t:string)=>{line.row.funeral_start = t}} readonly={false}
      /> */}
      <AntiOption line={line} readonly={forced_readonly()} />

      <EditNote prev={line.row.funeral_end}
        label="終了時間" placeholder="葬儀終了時間を入力してください"
        cb={(t:string)=>{line.row.funeral_end= t}} readonly={false}
      />

      <EditNote prev={line.row.funeral_denomination}
        label="宗派" placeholder="宗派を入力してください"
        cb={(t:string)=>{line.row.funeral_denomination= t}} readonly={false}
      />
      </>}


      {/* 法要 */}
      { ( (line.row.kind.includes('法要')===true) && (is_show_note(line) || editmode )) && <>

        {/* nenkiのチェック処理 (nenkiとmiscはどちらかがあればOK。)*/}
      <CheckNote init={line.row.hoyo_nenki}
        label="年忌法要" placeholder="四十九日、一周忌、三回忌など"
        cb={(t:string)=>{line.row.hoyo_nenki= t}}
      />

        {/* miscのチェック処理 (nenkiとmiscはどちらかがあればOK。)*/}
      <CheckNote init={line.row.hoyo_misc}
        label="その他" placeholder="墓閉まい、仏壇開眼などをご記入ください"
        cb={(t:string)=>{line.row.hoyo_misc= t}}
      />

      {/* <HoyoAt line={line} cb={cb_h_at} /> */}
      <HoyoAt line={line} editmode={forced_readonly()}/>

      <EditNote prev={line.row.hoyo_owner}
        label="姓名" placeholder="施主名"
        cb={(t:string)=>{line.row.hoyo_owner =t}} readonly={false}
      />

      <EditNote prev={line.row.hoyo_phone}
        label="電話" placeholder="施主電話番号"
        cb={(t:string)=>{line.row.hoyo_phone= t}} readonly={false}
      />
      
        {/* 住所単独入力欄は廃止@2022-1027 --> 連絡先 phone or mail を追加*/}
      {/* <EditNote2 prev={line.row.hoyo_addr}
        label="住所" placeholder="お参りする場所"
        cb={(t: string)=>{line.row.hoyo_addr= t}}
      /> */}

      {/* 法要の確認など連絡先としての mail  or  phone (@2022-1027) */}
      {/* チェックでの入力としたいとのこと 排他だけど checkでやるか... 編集を考えるとやはりラジオか。　*/}
      {/* <CheckNote init={line.row.memo}
        label="電話での予約確認" placeholder="お電話での予約確認連絡先をご記入ください"
        cb={(t:string)=>{line.row.memo= t}}
      />
      <CheckNote init={line.row.memo}
        label="メールでの予約確認" placeholder="メールでの確認先をご記入ください"
        cb={(t:string)=>{line.row.memo= t}}
      /> */}

        <PhoneOrMail line={line} />

      

      </>}

      {/* 予約ボタン 不可ならそもそも表示しないように変更*/}
      <div className="input-group" >
      { line.enable 
        ? (
          <button type="button" className="btn btn-outline-primary" disabled={!line.enable}
            onClick={()=>insr(line)}>
            {line.enable ? '予約' : '不可'}
          </button>
          )
        : (<></>)
      }

      <div className="form-control ellipsis" style={{backgroundColor: "WhiteSmoke"}}>
        {prompt(line, login)}
      </div>

      {/* ヘルプ詳細ボタン adminのみ --> 自分の予約はこれで確認することにした。 */}
      { is_editable(line) && (is_admin(login.username) || is_mine(line, login)) && 
        <div className="form-control" style={{backgroundColor: "WhiteSmoke"}}  >
          <input
            type="checkbox" 
            className="form-check-input me-1 pointer"
            checked={editmode}
            onChange={handle_editmode} 
            value="" />
            <label className="mx-3">
              {'編集'}
            </label>
        </div>
      }

      {/* 削除ボタン */}
      { (line.erasable && !editmode) &&
        <button type="button" className="btn btn-outline-primary" disabled={!line.erasable}
          onClick={()=>delr(line)}>
          削除
        </button>
      }
      {/* 更新ボタン (editmodeにできているなら、更新できるとする)*/}
      { editmode &&
        <button type="button" className="btn btn-outline-primary" disabled={false}
          onClick={()=>updr(line)}>
          更新
        </button>
      }
      { (is_admin(login.username) || is_mine(line, login)) && 
          <LineInfoBtn line={line} users={users} />
      }
      </div>

    </>)
  } // End of ALine

//
// ●DayForm
//
// 選択された一日についての予約実行の form
// (formStateをつかったvalidationはまだ、不要だ。)
// const { register, handleSubmit, watch, formState: { errors } } = useForm() のようなこと。
//-->https://react-hook-form.com/jp/get-started/ をみよ。
// lines2を追加。連日ペア予約の検討
//
// memoは memorandumなので 複数形は memos であり、 memoesではない。
// DayFormに渡されるユーザーは calendarが appからもらう、つまりtopレベルでの userつまり login userである。
//
//
// 〇DayForm入力引数の、型。(onSubmitは型なし sigh...)
//
type DayFormProps ={
  lines: Line[];
  user: UserType; // Calendarからの ログインユーザー
  item: ItemDType; // i,j,o で oは calendarにある d, rs[] の こと rsは dbの ReservationRowの配列
  calUsers: UserType[];
  onCancel: () => void;
  flags: Flags,
  //onSubmit: SubmitHandler<any>;
  onSubmit: ({cmd, line, item_dt}:{cmd: string; line: Line; item_dt: Date;}) => void;
}
export const DayForm = (
  {lines, user, item, calUsers, flags, onCancel, onSubmit}: DayFormProps) : JSX.Element => {

  //
  // 型ガード (d, rs[] がないのはdbからの取得ができていないエラーである。)
  //
  if(item.o === undefined){
    return (<div>DayForm Error. ReservationDay undefined!</div>)
  }
  //
  // ●insr (今は calendarの submit2 が call backされる。)
  //
  // line.optionに tuya+sogi+anti1+anti2 までの内容が なしあるいは二日、三日、四日、の３パタンで書かれてくる。
  // @2022-1027 管理者が法要については仮押さえするときがあるので check_notesは略する。
  // 2024 従来のcheck_notesはそのまま L, Aについては関数を分けて詳細対応できるようにした。
  const insr = (line: Line) => {

    const tag= kind2tag(line.row.kind)
    
    // 法要で管理者がログインしている場合は 仮予約として checkなしで GO.
    if( tag.startsWith('hoyo') && is_admin(user.username)){
      if(line.row.hoyo_owner === ''){
        line.row.hoyo_owner= `管理者による仮予約です(${user.username})`
      }
    }
    else if( tag.startsWith('hoyo')){
      const {success, msg} = check_notes(line.row)
      if(!success){
        window.alert(msg)
        return
      }
    }
    else if( tag === 'tuya'){
      const {success, msg} = check_notes(line.row)
      if(!success){
        window.alert(msg)
        return
      }
    }
    else if( tag === 'tuya-l'){
      const {success, msg} = check_notesL(line.row)
      if(!success){
        window.alert(msg)
        return
      }
    }
    else if( tag === 'tuya-a') {
      const {success, msg} = check_notesA(line.row)
      if(!success){
        window.alert(msg)
        return
      }
    }
    //
    if(line.row.user === '' ) {console.log('@@@dayform:insr row.user NONE !?')}
    const insr_opt = {cmd: 'insr', line, item_dt: item.o.d}
    if(window.confirm('予約 を実行しますか')){
      line.row.user = user.username //login userの名前で rowを整える。(必須？だっけ)
      onSubmit(insr_opt)
    }
  }
  //
  // ●delr (kqでは row.link の 2022-1009+anti, ... をみて関連するレコードを削除する)
  //
  const delr = (line: Line) => {
    const delr_opt = {cmd: 'delr', line, item_dt: item.o.d}
    if(window.confirm('削除 を実行しますか')){
      onSubmit(delr_opt)
    }
  }
  //
  // ●updr (編集後の更新処理の実行) @2022-1014
  //

const updr = (line: Line) => {
  const tes_phone= (text: string) => /^[0-9-]+$/.test(text)
  const tes_mail= (text: string) => /@/.test(text)
  
  //何の更新?
  const tag = kind2tag(line.row.kind)
  if( tag === 'tuya' || tag === 'tuya-h'){
    //OK no check 
  }
  else if( tag.startsWith('hoyo')){

    //連絡先のチェックは？可能か。
    const memo= line.row.memo
    if(memo===''){
      window.alert('予約確認のための連絡先がありません')
      return
    }
    if( tes_phone(memo) || tes_mail(memo) ){
      //OK 
    }else{
      window.alert('予約確認のための連絡先を正しく入力してください')
      return
    }
  }

  const updr_opt = {cmd: 'updr', line, item_dt: item.o.d}
  if(window.confirm('編集内容で 更新しますか ')){
    onSubmit(updr_opt)
    //console.log(`@@@updr ${line.row.kind}`)
  }
}
  //
  // (いわゆる form 的には使ってない insr/delr を emit するだけなので...)
  //1
  return (
  <div >

    <TitleCancel
      login={user}
      ymd={format(item.o.d, 'yyyy-MM-dd')}
      title='予約の作成または削除'
      onCancel={onCancel} />

    <CheckboxHeader />

    { (is_admin(user.username)||user.danka) && <>
      <div className="input-group" style={{ backgroundColor: "Linen", fontSize: "0.9rem" }}>
      初めての方は初回だけでも結構ですのでトラブル防止のため電話で予約確認お願い致します。
      </div>
      <div className="input-group" style={{ backgroundColor: "Linen", fontSize: "0.9rem" }}>
      月参り・お盆参り・彼岸参りの予約は出来ません電話でお問い合わせください
      </div>
      <div className="input-group" style={{ backgroundColor: "Linen", fontSize: "0.9rem" }}>
      遠方の方は前後の関係がありますのでお寺の方から確認の電話をさせて頂きます
      </div>

    
    </>}

    {/* 当日予約Lineの表示 */}
    {/* cday.linesでmapすると 描画で何らかの loopなど */}
  {
    lines.map( (line, i) =>
      <div key={i} style={{border: "1px solid darkblue"}}>
        <ALine line={line} login={user} users={calUsers} insr={insr} delr={delr} updr={updr}
          adminEdit={flags.fAdminEdit} /> 
      </div>
    )
  }

  </div>)

}
// dayform EOF